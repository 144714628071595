<template>
   <div class="layout-px-spacing">
      <portal to="breadcrumb">
         <ul class="navbar-nav flex-row">
            <li>
               <div class="page-header">
                  <nav class="breadcrumb-one" aria-label="breadcrumb">
                     <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="javascript:;">Users</a></li>
                        <li class="breadcrumb-item active" aria-current="page"><span>Profile</span></li>
                     </ol>
                  </nav>
               </div>
            </li>
         </ul>
      </portal>

      <div class="row layout-spacing">
         <div class="col-lg-12 layout-top-spacing">
            <div class="panel">
               <div class="panel-heading">
                  <div class="row">
                     <div class="col-12">
                        <h3>Vytvoriť novú rezerváciu</h3>
                     </div>
                  </div>
               </div>
               <div class="panel-body">
                  <b-form @submit.prevent="submitForm" ref="form">
                     <div class="row align-items-center mb-md-4">
                        <div class="col-12 mt-md-0 mt-4">
                           <b-form-row class="over-1">
                              <b-form-group label="Číslo OP" class="col-md-6">
                                 <b-form-input :state="validateState('op_number')" v-model="$v.form.op_number.$model" type="text"
                                               placeholder=""></b-form-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                              <b-form-group label="Meno klienta" class="col-md-6">
                                 <b-form-input :state="validateState('client_name')" v-model="$v.form.client_name.$model"
                                               type="text"
                                               placeholder=""></b-form-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>
                           <b-form-row class="over-1" style="z-index: 2">
                              <b-form-group label="Vytvoril" class="col-md-6">
                                 <b-form-input :state="validateState('from_user_name')" v-model="$v.form.from_user_name.$model"
                                               type="text" readonly
                                               placeholder=""></b-form-input>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>

                              <b-form-group class="col-md-6" label="Príjmateľ">
                                 <multiselect :showNoResults="false" v-model="$v.form.to_user_id.$model" deselectLabel="Vymazať"
                                              selectLabel="Vybrať" selectedLabel="Vybrané"
                                              tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name"
                                              track-by="id" :can-deselect="false" :allow-empty="false" :options="users"
                                              :multiple="false" :closeOnSelect="true"></multiselect>
                                 <!--                      <b-select :state="validateState('to_user_id')" v-model="$v.form.to_user_id.$model">
                                                         <b-select-option :value="null">Vybrať...</b-select-option>
                                                         <b-select-option v-for="user in users" :value="user.id" :key="user.id">
                                                           {{ user.name }}
                                                         </b-select-option>
                                                       </b-select>-->
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>

                           <b-form-row class="over-1">
                              <b-form-group class="col-md-6" label="Dátum realizácie">
                                 <b-form-datepicker :state="validateState('realisation_date')" id="datepicker" :min="new Date()" v-model="$v.form.realisation_date.$model"
                                                    rows="4"></b-form-datepicker>
                                 <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                              </b-form-group>
                           </b-form-row>

                           <b-form-row>
                              <b-form-group class="col-md-12" label="Produkty">
                                 <div class="form-repeater w-100 custom-table">
                                    <table class="table table-responsive-detail">
                                       <thead>
                                          <tr>
                                             <th style="width: 30%;min-width: 200px">Názov</th>
                                             <th style="width: 30%;min-width: 200px">Počet</th>
                                             <th style="width: 30%;min-width: 200px">Poznámka</th>
                                             <th class="text-center">Odstrániť</th>
                                          </tr>
                                       </thead>
                                       <tbody class="form-repeater">
                                          <tr v-for="(row, index) in form.rows" :key="index">
                                             <td>
                                                <!--                              :state="validateState('rows.$each.' + index + '.id')"-->
                                                <!--                          <multiselect v-model="$v.form.rows.$each[index].id.$model" :options="options1" :searchable="true"-->
                                                <!--                                       :preselect-first="true" selected-label="" select-label=""-->
                                                <!--                                       deselect-label=""></multiselect>-->
                                                <!--                          <b-select
                                                                              v-model="$v.form.rows.$each[index].id.$model"
                                                                              name="" id="" class="form-select form-control">
                                                                            <b-select-option v-for="item in items" :key="item.id" :value="item.id">
                                                                              {{ item.name }} ({{ item.category.name }})
                                                                            </b-select-option>
                                                                          </b-select>-->
                                                <!--                          <b-select v-model="$v.form.rows.$each[index].id.$model" name="" id="" class="form-select form-control">-->
                                                <!--                            <template #option="{ option }">-->
                                                <!--                              <div class="d-flex justify-content-between">-->
                                                <!--                                <span>{{ option.item.name }}</span>-->
                                                <!--                                <span class="badge">{{ option.item.category }}</span>-->
                                                <!--                              </div>-->
                                                <!--                            </template>-->
                                                <!--                            -->
                                                <!--                          </b-select>-->
                                                <multiselect :max-height="200" :showNoResults="false" v-model="row.id" deselectLabel="Vymazať"
                                                             selectLabel="Vybrať" selectedLabel="Vybrané"
                                                             tag-placeholder="Pridajte to ako novú značku" placeholder="Vyhľadať" label="name"
                                                             track-by="id" :can-deselect="false" :allow-empty="false" :options="items"
                                                             :multiple="false" :closeOnSelect="true"></multiselect>
                                             </td>
                                             <td>
                                                <b-input-group class="">
                                                   <template #append>
                                                      <b-input-group-text><strong v-if="row.id" class="">{{ row.id.unit.name }}</strong>
                                                      </b-input-group-text>
                                                   </template>
                                                   <b-form-input v-model="row.quantity" type="number" min="0" placeholder=""></b-form-input>
                                                </b-input-group>
                                                <b-form-invalid-feedback>Povinné pole</b-form-invalid-feedback>
                                             </td>
                                             <td>
                                                <b-form-input v-model="row.note" type="text" placeholder=""></b-form-input>
                                             </td>
                                             <td class="text-center align-middle">
                                                <b-button variant="outline-danger" @click="removeRow(index)">
                                                   <svg
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     width="24"
                                                     height="24"
                                                     viewBox="0 0 24 24"
                                                     fill="none"
                                                     stroke="currentColor"
                                                     stroke-width="2"
                                                     stroke-linecap="round"
                                                     stroke-linejoin="round"
                                                     class="feather feather-trash-2"
                                                   >
                                                      <polyline points="3 6 5 6 21 6"></polyline>
                                                      <path
                                                        d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                      <line x1="10" y1="11" x2="10" y2="17"></line>
                                                      <line x1="14" y1="11" x2="14" y2="17"></line>
                                                   </svg>
                                                </b-button>
                                             </td>
                                          </tr>

                                          <tr class="text-md-center">
                                             <td colspan="6">
                                                <b-button variant="outline-dark" @click="addRow">
                                                   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                        class="feather feather-plus-circle">
                                                      <circle cx="12" cy="12" r="10"></circle>
                                                      <line x1="12" y1="8" x2="12" y2="16"></line>
                                                      <line x1="8" y1="12" x2="16" y2="12"></line>
                                                   </svg>
                                                </b-button>

                                             </td>

                                          </tr>
                                       </tbody>
                                    </table>
                                 </div>
                              </b-form-group>
                           </b-form-row>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 text-right">
                           <b-button @click="$router.go(-1)" variant="danger" class="mt-3">Zrušiť</b-button>
                           <b-button type="submit" variant="primary" class="mt-3 ml-2">Vytvoriť</b-button>
                        </div>
                     </div>
                  </b-form>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
   import { validationMixin } from "vuelidate";
   import { required } from "vuelidate/lib/validators";
   import Multiselect from "vue-multiselect";
   import "vue-multiselect/dist/vue-multiselect.min.css";

   export default {
      name: "new",
      mixins: [validationMixin],
      components: { Multiselect },

      validations: {
         form: {
            op_number: {
               required
            },
            client_name: {
               required
            },
            from_user_id: {
               required
            },
            from_user_name: {
               required
            },
            to_user_id: {
               required
            },
            realisation_date: {
               required
            },
            rows: {
               required,
               $each: {
                  id: {
                     required
                  },
                  quantity: {
                     required
                  }
               }
            }
         }
      },
      data() {
         return {
            form: {
               op_number: "",
               client_name: null,
               from_user_name: null,
               from_user_id: null,
               to_user_id: null,
               realisation_date: "",
               rows: [{ id: null, quantity: "", note: "", unit: "" }]
            }
         };
      },
      created() {
         this.$axios.get("user")
           .then(response => {
              this.form.from_user_id = response.data.pouzivatel.id;
              this.form.from_user_name = response.data.pouzivatel.name;
           });
         this.$store.dispatch("user/getAllUsers");
         this.$store.dispatch("item/getItems", { all: 1 });

      },
      computed: {
         users() {
            return this.$store.state.user.users;
         },
         items() {
            return this.$store.state.item.items;
         }
      },
      methods: {
         minDate() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();

            today = yyyy + "-" + mm + "-" + dd;
            console.log(today);
            return today;
         },
         addRow() {
            this.form.rows.push({ id: null, quantity: 0, note: "", unit: "" });
         },
         removeRow(index) {
            this.form.rows.splice(index, 1);
         },
         validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
         },
         submitForm() {
            // this.form.rows.forEach(function (value) {
            //   if (value.id == null || value.quantity == null) {
            //     this.errors.push('Item is not correct');
            //   }
            // })

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
               // console.log(this.form);

               this.$toast.error("Chýbajúce údaje vo formulári");
               return;
            }

            let data = {
               op_number: this.form.op_number,
               client_name: this.form.client_name,
               from_user_id: this.form.from_user_id,
               to_user_id: this.form.to_user_id.id,
               realisation_date: this.form.realisation_date
            };

            let formData = new FormData();

            for (let key in data) {
               formData.append(key, data[key]);
            }


            this.form.rows.forEach(function(value, index) {
               formData.append("items[" + index + "][id]", value.id.id);// you have to add array symbol after the key name
               formData.append("items[" + index + "][quantity]", value.quantity);// you have to add array symbol after the key name
               formData.append("items[" + index + "][note]", value.note);// you have to add array symbol after the key name
            });
            // Display the key/value pairs


            this.$axios.post("reservations", formData)
              .then((response) => {

                 if (response.status === 200 || response.status === 201) {
                    this.$store.dispatch("user/getAllUsers");
                    this.$router.go(- 1);
                    this.$toast.success(response.data.message);
                 } else {
                    console.log(response);

                    this.$toast.error(response.data.message);

                 }
                 // console.log(response)
                 // this.$toast.success(response.data.message);

              }).catch(error => {
               console.log(error);
               this.$toast.error(error.response.data.message);
            });
         }
      },
      watch: {
         /*users() {
           this.form.to_user_id = this.users.filter(user => user.id === 2)[0].id
         }*/
      }
   };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
   @import '@/assets/sass/global/table.scss';
</style>
